
    @import "config.scss";
 
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block: 1rem;

  @include for-desktop-up {
    ::-webkit-scrollbar {
      display: none;
    }
  }
}

.indicators {
  position: relative !important;
  bottom: 0px !important;
  left: 0% !important;
  margin-top: 20px;
  flex-direction: row !important;
  transform: none !important;
}

.chevrons {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: #121212;
  opacity: 0.7;
  font-size: 4rem;
  z-index: var(--z-chevron);
  position: absolute;
}

.slider {
  width: 100%;
  display: flex;
  align-items: center;
  transition: scroll 1s;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sliderContainer {
  display: flex;
  height: 100%;
  width: 100%;
}

.bannerWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  flex: 0 0 auto;
}

.img {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: relative;

  a {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .chipsInBanner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  img {
    height: 100%;
    max-width: 100vw;
    width: 100%;
    object-fit: cover;
  }
}

.video {
  height: 100%;
  max-width: 100vw;
  width: 100%;
  object-fit: cover;
}
