
    @import "config.scss";
 
.link {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-grey);
}

.primary {
  color: var(--color-primary);
}

.center {
  justify-content: center;
}

.allWidth {
  width: 100%;
  display: flex;
  justify-content: center;
}
