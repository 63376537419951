
    @import "config.scss";
 
.skeleton {
  width: 100%;
  height: 100%;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  opacity: 0.7;
  margin: 5px 0;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}
.dark {
  background: linear-gradient(110deg, #272727 8%, #333333 18%, #272727 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}

.productCardSkeleton {
  border-radius: var(--br-hard);
  width: 100%;
  height: 364.7px;
  @include for-desktop-up {
    height: 411.71px;
  }

  background: linear-gradient(
    0deg,
    var(--color-grey) 0%,
    #1d1d1d 12%,
    #2e2e2e 20%,
    #1d1d1d 40%,
    var(--color-grey) 70%
  );
  background-size: 50% 200%;
  animation: 3.5s shine linear infinite;
  @keyframes shine {
    to {
      background-position-y: -200%;
    }
  }
}
