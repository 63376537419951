
    @import "config.scss";
 
.colored {
  & path {
    fill: var(--color-primary);
  }
}

.arrowColored {
  & path {
    stroke: var(--color-primary);
  }
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reverse {
  transform: scaleX(-1);
}
